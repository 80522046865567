/*
    Use the DejaVu Sans font for displaying and embedding in the PDF file.
    The standard PDF fonts do not support Unicode characters.
*/

main{
    overflow-y: auto;
}

/*
    The example loads the DejaVu Sans from the Kendo UI CDN.
    Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is
    https://dejavu-fonts.github.io/.
*/
@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}
@font-face {
    font-family: "Arial";
    // font-weight: 200;
    //  src: url("../fonts/ARIAL.TTF") format("truetype");
    src: url("../fonts/ARIAL.TTF") format("truetype");

}

@font-face {
    font-family: "ArialBold";
    font-weight: bold;
    src: url("../fonts/arialbd.ttf") format("truetype");

    // font-weight: 200;
    //  src: url("../fonts/ARIAL.TTF") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans Bold";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");

}
.splash {
  position : absolute ; 
  top : calc(50vh - 250px);
   left :calc(50vw - 250px)
}
.site-logo-dashboard{
    width : 95px;
     height : 25px;
     color: white;
}
.send{
    background: #8adc8a;
    border-radius: 29px;
    font-size: 12px;
    width: 50px;
    text-align: center;
    padding: 1px;
   
}
.notsend {
    background: #ff0000a6;
    border-radius: 29px;
    width: 50px;
    font-size: 12px;
    text-align: center;
    padding: 1px;
}
.order-page {
    .MuiOutlinedInput-inputMultiline {
        min-width: 180px !important;
    }
    .td-note {
        white-space: pre !important;
    }
}

.logo-td {
    width: 35px;  
    height : 35px
}
.add-icon{
    float: right;
}
.grid-table{
    width: 100%;
    height: calc(100vh - 300px);
  }
  .medication-content{
      display: flex;
      flex-direction: column;
      justify-self: start;
      padding-left: 15px;
      >div {
          width: 100%;
          font-size: 12px;
      }
  }
  .MuiBox-root{
    p{
        margin-bottom: 0px !important;      
    }
}
.textArea {
    width: 100%;
    height: auto;
}
.full-width {
  width: 100% !important;
}
.k-autocomplete {
    font-size: 12px !important;
}
.rowContainer {
    display: flex;
    justify-content: left;
    width: 100%;
    p{
        font-size: 14px !important;
    }
  
}

.MuiTypography-body1{
    font-size: 14px !important;
}
.gridItem {
    >div {
        padding: 10px 0px !important;
    }
  
}
.user-logo{
       
    padding: 5px;
    img{
        border-radius: 50% !important;
    }
}
.order-detail {
    background-color:#e0e0e04d !important;
    overflow: auto;
    .MuiTypography-h4{
        font-size: 150%;

    }
    .logo-mini{
        img{
            width: 150px;
            height: auto;
        }
      
    }
}
.font-2  {
    font-size: 14px;
    font-weight: bold;
    color: rgba(10, 10, 10, 0.589);
    p{
        font-size: 14px !important;
        font-weight: bold;
        color: rgba(10, 10, 10, 0.589); 
    }
}
.warp-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 24px;
    div{
        color: white;
    font-weight: 300;
    }
}
.textArea {
    background: #f1f1f1c9 !important;
}
.buttonContainer {
    display: flex;
    justify-content: start;
    margin-top: 17px;
}
.comment {
    margin: 21px 0px;
    word-wrap: break-word;
}
.pointer {
    cursor: pointer;
    color: blue !important;
}
.createBtn {
    display: flex;
    justify-content: end;
}
.MuiDialog-paperWidthSm {
    max-width: 835px !important;
    .note-toolbar {
        background: #eae6e6;
        border: 1px solid #dad7d7;
        text-align: left;
    }
}
.patient-container {
 
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
        padding-right: 33px;
    }
    .k-autocomplete {
        width: auto !important;
        min-width: 120px;
    }
    .k-multiselect, .k-dropdowntree {
        min-width: 150px;
    }
    .MuiInputLabel-formControl {
        font-size: 10px;
        min-width: 150px;
       
    }
    .MuiTableCell-body .MuiSvgIcon-root {
        width: 14px;
        height: 14px;
        margin: 0px;
    }
    
}
@media (min-width: 1280px)
{   
    .MuiGrid-grid-lg-10 {
        flex-grow: 0;
        max-width: 78% !important;
        flex-basis: 78% !important;
    }
    .MuiGrid-grid-lg-2 {
        flex-grow: 0;
        max-width: 22% !important;
        flex-basis: 22% !important;
    }
 
}
@media (min-width: 1680px)
{   
    .MuiGrid-grid-lg-10 {
        flex-grow: 0;
        max-width: 82% !important;
        flex-basis: 82% !important;
    }
    .MuiGrid-grid-lg-2 {
        flex-grow: 0;
        max-width: 18% !important;
        flex-basis: 18% !important;
    }
 
}
@media (max-width: 768px)
{   
    .patient-detail{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        >div{
            width: 100%;
        }
    }
 
}
.box {
    margin-top: 10px;
    padding: 30px 30px 30px 0px;
    width: 61%;
    >div {
        padding: 10px 10px 10px 0px;
    }
}
.p-10 {
    padding: 10px !important;
}
.closeBtn {
    float: right;
    color: white;
}
.pointerIcon {
    cursor: pointer;
}
.title-content{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}
.title {
    font-size: 16px;
    text-align: left;
    padding: 0px;
    color: black;
    font-weight: bold;
}
.MuiTableCell-body .MuiSvgIcon-root{
    margin: 5px;
}
.carefolderContainer {
    max-width: 650px;
    margin: 0 auto;


}
.key-animation-container-shown{
    z-index: 500 !important;
    
}
.note-editable {
    height: 100% !important;
    min-height: 400px;
}
.downloadPaper {
    max-width: 895px !important;
    .pageContainer {
        min-height: 1123px;
        margin: 10px 0px;
        // .contentHtml {
        //     min-height: 800px;
        // }
    }    
}

.serviceContainer {
    display: flex;
    justify-content: space-evenly;
    svg{
        cursor: pointer;
    }
    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.headerBar {
    display: flex;
    justify-content: left;
    align-items: baseline;
    height: 50px;
    >h5 , div {
        margin-top: auto;
        margin-bottom: auto;
        color : #ff6a00;
        font-size: 13px;
    }
    img {
        margin-right: 30px;       
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
}
.footerBar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
       color : #ff6a00
}
html{
    @page {
        margin:20 !important;
        padding: 0px 0px 0px 0px !important;
    }
}
.note-editing-area {
    text-align: left !important;
}
.triggerContainer {
    .MuiGrid-container {
        display: flex;
        justify-content: space-around !important;
        li{
            cursor: pointer;
            padding: 20px 0px;
            .MuiSvgIcon-root {
                font-size: 40px;
            }
        }
        li:active , li:hover , li.clicked{
            background: #f1f1f1;
        }
        .MuiCardContent-root {
            height: 100%;
        }
        .previewPanel {
            height: 100% !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: space-evenly !important;
           .triggerTitle {
            font-size: 20px;
            font-weight: 500;
            color: #1357ff;
            padding: 10px;
           }
          
            .k-multiselect-wrap ,.k-autocomplete{
           
                width: 100%;
                font-size: 12px;
                margin-left: 10px;
            }
            .MuiBox-root-636 , .MuiBox-root-637 .MuiBox-root-638{
                text-align: center;
                font-weight: bold;
                font-size: 15px;
            }
            .MuiPaper-elevation1 {
                margin-bottom: 20px;
            }
        }
    }
}
.document,#downloadArea {    
    font-family: 'DejaVu Sans' !important;
    color: black !important;
    strong, .title, .strong {
        font-family: 'DejaVu Sans Bold' !important;
    }
    .title-content{
        flex-direction: column;
        text-align: left;
    }
    .logo{
        width: 150px;
        padding: 30px 10px;
    }
    // div , p , span , label {
    //     // font-family: "Arial" !important;   
    //     font-family: "DejaVu Sans" !important;   
    //     // font-weight: 200 !important;
    //     color: black !important;
    //   //  font-size: 12px;
    // }
    // strong, h1,h2, h3, h4,h5,h6 {
    //    font-family: 'DejaVu Sans Bold' !important;
    // //    font-weight: 200 !important;
    //     color:black !important;
    // }
    // .title{
    //     // font-family: "ArialBold" !important;   
    //     font-family: "DejaVu Sans Bold" !important;   
    //     color: black !important;
    // }
    // .name{
    //     // font-family: "Arial" !important;   
    //     font-family: "DejaVu Sans" !important;   
    //     color: black !important;
    // }
    // .insurance{
    //     // font-family: "Arial" !important; 
    //     font-family: "DejaVu Sans" !important;   
    //     color: black !important;
    // }
    // .insuranceNr{
    //     // font-family: "Arial" !important;  
    //     font-family: "DejaVu Sans" !important;   
    //     color: black !important;
    // }
    // .birthday{
    //     // font-family: "Arial" !important; 
    //     font-family: "DejaVu Sans" !important;   
    //     color: black !important;
    // }
    // #footer{
    //     // font-family: 'Arial' !important;
    //     font-family: "DejaVu Sans" !important;   
    //     font-weight: bold !important;
    // }
   
}
.orderdetail {
    .title-fontSize {
        font-size: 32px;
        padding: 0px !important;
        .titlecolor{
            color: #505761;
        }
    }
    .content-fontSize {            
         font-size: 20px;
    }
    .gridItem {
        position: relative;
        .header {
            display: flex;
            justify-content: space-between;
            >div{
                width: 30%;
                margin: auto 0px;
                font-weight: bold;
            }
           
        }
        .printBtn {
            width: 100px;
            top: 50px;
            right: -10px;
            position: absolute;
            .MuiSvgIcon-root {
                font-size: 2.7rem;
            }
        }
        .patient {

        }
        .medication {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
         .MuiCard-root {
            width: 329px !important;
            border-radius: 10px;
            margin: 30px 0px;
         }
         
        }
     
      
    }
    .gridItem-right {
        background-color: #4d7df2 !important;
        padding: 100px 48px 60px 62px !important;
        .title-fontSize {
            color: white;

        }
        .MuiTypography-root {
            color: white;
        }
        >.MuiBox-root {
            margin: 30px 0px;
        }
    }
   
}
.title-content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    >div {
        width: 50%;
    }
}

@media (max-width: 768px){
    .MuiBox-root-532 {
        padding: 60px !important;
    }
    .orderdetail {
        .title-fontSize {
            font-size: 6vw;
            padding: 0px !important;
            .titlecolor{
                color: #505761;
            }
        }
        .content-fontSize {            
             font-size: 12px;
        }
        .gridItem {
            position: relative;
            .header {
                display: flex;
                justify-content: space-between;
                >div{
                    width: 100%;
                    margin: auto 0px;
                }
                margin-bottom: 30px;
               
            }
            .printBtn {
                width: 100px;
                top: 84px;
                left: 18px;
                right: 0px;
                position: absolute;
                .MuiSvgIcon-root {
                    font-size: 2rem;
                }
                cursor: pointer;
            }
            .patient {
    
            }
            .medication {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
             .MuiCard-root {
                width: 329px !important;
                border-radius: 10px;
                margin: 30px 0px;
             }
             
            }
         
            .MuiOutlinedInput-notchedOutline{
                border-color: white;
            }
        }
        .gridItem-right {
            background-color: #4d7df2 !important;
            padding: 20px !important;
            .title-fontSize {
                color: white;
    
            }
            .MuiTypography-root {
                color: white;
            }
            >.MuiBox-root {
                margin: 30px 0px;
            }
        }
       
    }
    .title-content {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
        >div {
            width: 100%;
        }
    }
    
}
.k-animation-container{
    z-index: 10000
  }
#editArea {
    margin: 10%;
    position: relative;
    font-family: Arial !important;
    color: black !important;
    .MuiTypography-body1 {
        font-family: Arial;
    }
    strong {
        font-family: 'Arial' !important;
        color: black !important;
    }
    table {
        td ,tr ,th {           
             text-align: center;
             padding: 5px;
             font-family: Arial !important;
        }
        .thead{
            background-color: #00000017;
            .th{                
                text-align: left;
                width: 70%;
              }
              .th2 {
                width: 30%;
            }
        }
         
    }
    table, th, td {
        border: 0.0001px solid #0000005c;
        border-collapse: collapse;
      }
    .logo {
        position: absolute;
        width: 23%;
        right: 0;
        top: -43px;
        img {
            width: 100%;
        }
    }
    .checkboxs {
        
        h6 ,h5, label {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 15px;
        }
        .title-h5 {
            padding: 0px;
            font-family: Arial;
            font-weight: 200;
        }
    }
    .datecontainer {
        h6 , p ,div {
            margin-left: 0px;
            margin-top: auto;
            margin-bottom: auto;
        }
        .justify-around {       
            flex-wrap: unset;
        }
        p {
            padding-left: 3px;
        }
        
    }
    .tableContainer {
        label {
            font-size: 12px;
        }
        .MuiFormControl-marginNormal{
            width: 100%;
            margin-top: 0px;
            margin-bottom: 10px;
        }
        .box2 {
            width: 12%;
            font-size: 11px;
            font-family: Arial !important;
        }
        .detail {
            h6 {
                line-height: 1;
                margin: 0px;
                font-family: 'Arial' !important;
            }
            text-align: left;
            padding: 10px 5px;
        }
       
        td , th {
            font-family: Arial !important;

        }
    }
  div {
    font-size: 11px;
  }
    .marginAuto {
        margin-top: auto;
        margin-bottom: auto;
    }
        .justify-around {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }    
    .justify-between {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .flex-column {
        display: flex;
        flex-direction: column;      
        font-family: Arial !important;  
    }
    .justify-center {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        >div {
            margin-top: auto;
            margin-bottom: auto;
            font-family: Arial !important;
        }
        h5 {
            margin-left: 10px;
            font-family: 'Arial' !important;
            font-size: 14px;
        }
    }
    // checkbox
    .checkboxContainer {
        display: block;
        position: relative;
        padding-left: 22px;
        margin-bottom: 12px;
        height: 25px;
        cursor: pointer;
        font-size: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checkboxContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        width: 23px;
        background-color: white;
        border: 1px solid black;
      }
      
      /* On mouse-over, add a grey background color */
      .checkboxContainer:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .checkboxContainer input:checked ~ .checkmark {
        background-color: white;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .checkboxContainer input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .checkboxContainer .checkmark:after {
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;   
        background-image: url(../Images/remove.png);
        background-size: 100% 100%;
      }
      
      .MuiBox-root p {
          color: black;  
         font-size: 12px !important;
      }
    //   div, label, p, h5,h6 {
    //       font-family: 'DejaVu Sans' !important;
    //   }
}
.red {
    color: red;
}
@media (max-width: 768px){
    #editArea .justify-center {
        justify-content: left;
        
    }
}
.attachPdf {
    width: 530px;
    position: fixed; 
    right: -980px;
    font-family: Arial !important;
    #editArea {
        padding-top: 30px;
        .logo {          
            top: 0px;
        }
      .checkboxContainer .checkmark:after {
            left: 3px;
            top: 2.5px;
            width: 50%;          
            height: 50%;          
            background-size: 100% 100%;
            background: black;
        }
        width: 470px;
        margin: 0px; 
        position: relative;
        .checkmark {      
            height: 13px;
            width: 13px;
          }
          .checkboxs {
          
            div {
                margin: 0px !important;
               }
            h6 ,h5, label {
               margin: 0px;
              
                padding: 0px 5px;
                font-size: 10px;
                font-family: 'Arial' !important;
            }   
            .title-h5 {
                padding: 0px !important;              
            }    
            // h5 {
            //     padding: 0px;
            // }
        }
        td ,tr ,th {            
             text-align: center;
             padding: 5px;
        }
        table, th, td {
            border: 0.0001px solid #0000005c;
            border-collapse: collapse;
          }
        div {
            font-size: 9px;
            font-family: Arial !important;
        }
        .tableContainer {
            label {
                font-size: 9px;
                font-family: Arial !important;
            }
       
            .box2 {
                
                font-size: 9px;
                font-family: Arial !important;
            }
        }
        .MuiBox-root p {
            color: black;  
           font-size: 9px !important;
           font-family: Arial !important;
        }
        h6{
            font-size: 10px;
        }

      
    }
    .none-row {
        text-align: center !important;
    }
   
      
}
.patients {
   
    .td-note {
        white-space: pre !important;
    }
    textarea {
        padding: 0;
        min-width: 200px !important;
    }
}

    .patientsContainer {
        tr {
            width: 320px;
        }
        height: 60vh;
        /* position: absolute; */
        width: 380px;
        top: 12vh;
        position: absolute;
        left: 50px;
        z-index: 100;
        .MuiTableRow-root {
            width: 100%;
            display: flex !important;
            justify-content: space-between;
           
        }
   
        .MuiCard-root {
            height: 100%;
        }
        .content {
            margin-top: 6vh;
            >div{
                overflow-y: auto;
               height: 44vh;
               padding-top: 30px;
             
            }
            h6 {
                margin: 40px 0px 20px 0px;
            }
            table {
                min-width: auto;
             
            }
        }
     
      
    }
    .pac-target-input , .pac-target-input:active {
        border: none;
        border-bottom: 1px solid #ccc9c9 ;
        width: 351px !important;
        left: 59px !important;
    }
    .pac-target-input:active {
        border: none !important;
        border-bottom: 1px solid greenyellow !important ;
    }
    .ausgaben {
        .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
            width: 0;
            min-width: 92px;
        }
        .linkdiv {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
        }
    }
